// TODO prefix table with timing?
import { table } from "../utils/pg-ts/pg-ts";
import {
    commonIdCodec,
    nullable,
    pgIntegerCodec,
    validIsoDateStringCodec,
} from "./common";
import { NonEmptyString } from "io-ts-types";
import * as t from "io-ts";
import { flow, pipe } from "fp-ts/function";
import { array, either, number, option, readonlyArray, record } from "fp-ts";
import { contramap } from "fp-ts/Ord";
import { assertUnreachable } from "../utils/assertUnreachable";
import { pick } from "../utils/fp";

export const formatToRegExpStr = (format: TimeEntryFormat) => {
    const placeholdersRe = pipe(
        timeEntryPlaceHoldersRegExpMap,
        record.keys,
        (placeholders) => new RegExp(`[${placeholders.join("")}]`, "g")
    );

    return pipe(
        format.replace(placeholdersRe, (placeHolder) =>
            pipe(
                timeEntryPlaceHoldersRegExpMap,
                record.lookup(placeHolder),
                option.fold(
                    () => "",
                    (re) => `(${re})`
                )
            )
        ),
        (regExp) => `^${regExp}$`
    );
};

export const orderedPlaceHoldersListFromFormat = (
    format: TimeEntryFormat
): Array<TimeEntryPlaceHolder> =>
    pipe(
        timeEntryPlaceHoldersRegExpMap,
        record.mapWithIndex((k) => format.indexOf(k)),
        record.toArray,
        array.filter((elem) => elem[1] > -1),
        array.sort(placeholderOrdByIndex),
        array.map((elem) => elem[0])
    );

export const timeEntryFormatCodec = t.string; // TODO only allowed chars, check for unique timeSegmentPlaceholder usage, trimmed?
type TimeEntryFormat = t.TypeOf<typeof timeEntryFormatCodec>;

const timeEntryPlaceHoldersRegExpMap = {
    H: "[0-9]+",
    M: "[0-5]?[0-9]",
    S: "[0-5]?[0-9]",
    s: "[0-9]+",
};
type TimeEntryPlaceHolder = keyof typeof timeEntryPlaceHoldersRegExpMap;

const placeholderOrdByIndex = contramap(
    (placeHolderPosition: [string, number]) => placeHolderPosition[1]
)(number.Ord);

export const placeHolderValueInMillis = flow(
    (placeholder: TimeEntryPlaceHolder, value: string) => {
        switch (placeholder) {
            case "H":
                return Number.parseInt(value) * 60 * 60 * 1000;

            case "M":
                return Number.parseInt(value) * 60 * 1000;

            case "S":
                return Number.parseInt(value) * 1000;

            case "s":
                return Math.round(Number.parseFloat("0." + value) * 1000);
        }

        assertUnreachable(placeholder);
    },

    option.fromPredicate((valueInMillis) => !isNaN(valueInMillis))
);

type TimeEntryValidatorError =
    | "regexp"
    | "placeholderValueInvalid"
    | "outOfRange";
export const timeEntryValidator = (format: TimeEntryFormat) => {
    const usedPlaceHolders = orderedPlaceHoldersListFromFormat(format);
    const formatRegExp = pipe(format, formatToRegExpStr, (s) => new RegExp(s));

    return (cellValue: unknown) =>
        pipe(
            String(cellValue),
            (s) => s.match(formatRegExp),
            option.fromNullable,
            either.fromOption((): TimeEntryValidatorError => "regexp"),
            either.chainW((groups) => {
                return pipe(
                    usedPlaceHolders,
                    array.mapWithIndex((index, placeholder) =>
                        pipe(
                            groups,
                            array.lookup(index + 1),
                            option.chain((value) =>
                                placeHolderValueInMillis(placeholder, value)
                            )
                        )
                    ),
                    option.sequenceArray,
                    option.map(readonlyArray.reduce(0, (acc, a) => acc + a)),
                    either.fromOption(
                        (): TimeEntryValidatorError => "placeholderValueInvalid"
                    ),
                    either.chainW(
                        flow(
                            storedTimeEntryCodec.decode,
                            either.mapLeft(
                                (): TimeEntryValidatorError => "outOfRange"
                            )
                        )
                    )
                );
            })
        );
};

export const storedTimeEntryCodec = t.intersection(
    [
        pgIntegerCodec,
        pgIntegerCodec,
        // positiveNumberCodec // TODO whole row validation
    ],
    "TimeEntry"
); // times are stored as milliseconds
export type StoredTimeEntry = t.TypeOf<typeof storedTimeEntryCodec>;

export interface TimeEntry {
    H: number;
    M: number;
    S: number;
    m: number;
}

export const timeEntryFromStored = (v: StoredTimeEntry): TimeEntry => {
    const H = Math.floor(v / (1000 * 60 * 60));
    const vv = v % (1000 * 60 * 60);

    const M = Math.floor(vv / (1000 * 60));
    const vvv = vv % (1000 * 60);

    const S = Math.floor(vvv / 1000);
    const m = vvv % 1000;

    return {
        H,
        M,
        S,
        m,
    };
};

export const twoDigits = (n: number) => (n > 9 ? String(n) : `0${n}`);
const threeDigits = (n: number) => (n > 99 ? String(n) : `0${twoDigits(n)}`);

export const timeEntryToString = (te: TimeEntry) => {
    // const m = Math.round(te.m / 100); this is wrong! rounding must happen during conversion from stored to timeEntry
    return `${te.H}:${twoDigits(te.M)}:${twoDigits(te.S)}${
        te.m == 0 ? "" : `,${threeDigits(te.m)}`
    }`;
};

export const gunshotsTable = table("gunshots", {
    id: commonIdCodec,
    event_id: commonIdCodec,
    gunshot: validIsoDateStringCodec, // TODO rename to gunshot time?
});

const bibCodec = NonEmptyString;
const timeValidatorWithMillis = timeEntryValidator("H:M:S.s");
const timeValidatorWithoutMillis = timeEntryValidator("H:M:S");
export const timeEntryFromTimeStringCodec = new t.Type<StoredTimeEntry, string>(
    "LocalDateStringFromDate",
    storedTimeEntryCodec.is,
    (u, ctx) =>
        pipe(
            [timeValidatorWithMillis(u), timeValidatorWithoutMillis(u)],
            array.filterMap(option.fromEither),
            array.head,
            option.fold(
                () => t.failure(u, ctx, `unsupported input: ${u}`),
                t.success
            )
        ),
    flow(timeEntryFromStored, timeEntryToString)
);

export const bibPassTable = table("bib_pass", {
    id: commonIdCodec,
    time: t.string, // TODO
    bib: bibCodec,
    ignored: nullable(t.boolean),
});

export const bibPassAfterStartView = table("bib_pass_after_start", {
    ...pipe(bibPassTable.columns, pick("id", "time", "bib", "ignored")),
    race_id: commonIdCodec, // dependency import loop
    time_relative: t.number,
});

export const storedTimeEntryToString = flow(
    timeEntryFromStored,
    timeEntryToString
);

export const timingServerTable = table("timing_server", {
    id: commonIdCodec,
    event_id: commonIdCodec, // dependency import loop
    droplet_id: commonIdCodec,
});
