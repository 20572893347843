import { Messages } from "../utils/i18n/i18n";
import * as t from "io-ts";
import isStrongPassword from "validator/lib/isStrongPassword";

export interface PasswordInputBrand {
    readonly PasswordInput: unique symbol;
}
const passwordInputCodecConfig = {
    minLength: 8,
    minLowercase: 0,
    minUppercase: 0,
    minNumbers: 0,
    minSymbols: 0,
};
export const passwordInputMinLengthRule = {
    message: `Heslo by se mělo mít aspoň ${passwordInputCodecConfig.minLength} znaků`,
    value: passwordInputCodecConfig.minLength,
};

export const passwordInputCodecMessages: Messages<() => string> = {
    en: () =>
        `minimum required length is ${passwordInputCodecConfig.minLength} chars`,
    cs: () =>
        `minimální požadovaná délka je ${passwordInputCodecConfig.minLength} znaků`,
};
export const passwordInputCodec = t.brand(
    t.string,
    (s): s is t.Branded<string, PasswordInputBrand> =>
        isStrongPassword(s, passwordInputCodecConfig),
    "PasswordInput"
);
