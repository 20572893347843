import { SupabaseClient } from "@supabase/supabase-js";
import * as React from "react";

const SupabaseClientContext = React.createContext<SupabaseClient | null>(null);

interface Props {
    client: SupabaseClient;
    children: React.ReactNode;
}

export const SupabaseProvider = (p: Props) => {
    return (
        <SupabaseClientContext.Provider value={p.client}>
            {p.children}
        </SupabaseClientContext.Provider>
    );
};

// TODO every supabase api call probably tested? or e2e is enough
// TODO move hooks into one place => easier to have an idea what is used (then placement in component)
export const useSupabase = () => {
    const client = React.useContext(SupabaseClientContext);

    if (client == null) {
        throw new Error(
            `useSupabase must be used within ${SupabaseProvider.name}`
        );
    } else {
        return client;
    }
};
