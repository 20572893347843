import React from "react";
import Head from "next/head";
import light from "../theme/light";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { SupabaseProvider } from "../utils/supabase/useSupabase";
import { supabase } from "../utils/supabase/init";
import PasswordChangeModal from "../components/xathlo/PasswordChangeModal";
import {
    GlobalAlerts,
    GlobalAlertsProvider,
} from "../components/common/GlobalAlerts";
import { AppProps } from "next/app";
import { UserContextProvider } from "../utils/supabase/useUser";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LangProvider } from "../utils/i18n/lang";
import { pipe } from "fp-ts/function";
import { Lang, langCodec, langDefault } from "../utils/i18n/i18n";
import { option } from "fp-ts";
import { createEmotionCache } from "../components/createEmotionCache";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";
import csLocale from "date-fns/locale/cs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Analytics } from "@vercel/analytics/react";
import { LicenseInfo } from "@mui/x-license-pro";
import { MicrosoftClarity } from "../components/MicrosoftClarity";

const clientSideEmotionCache = createEmotionCache();
// clientSideEmotionCache.compat = true;

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

const localeMap: Record<Lang, any> = {
    en: enLocale,
    cs: csLocale,
};

export default function MyApp(props: MyAppProps) {
    const {
        Component,
        pageProps,
        emotionCache = clientSideEmotionCache,
    } = props;

    const lang = pipe(
        props.router.locale,
        langCodec.decode,
        option.fromEither,
        option.getOrElse(() => langDefault)
    );

    return (
        <>
            <Analytics />
            <MicrosoftClarity />
            <CacheProvider value={emotionCache}>
                <Head>
                    <title>xathlo</title>
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width"
                    />
                </Head>
                <LangProvider lang={lang}>
                    <ThemeProvider theme={light}>
                        <CssBaseline />
                        <GlobalAlertsProvider>
                            <SupabaseProvider client={supabase}>
                                <UserContextProvider supabaseClient={supabase}>
                                    {/*For date pickers*/}
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={localeMap[lang]}
                                    >
                                        <PasswordChangeModal
                                            supabase={supabase}
                                        />
                                        <GlobalAlerts />
                                        <Component {...pageProps} />
                                    </LocalizationProvider>
                                </UserContextProvider>
                            </SupabaseProvider>
                        </GlobalAlertsProvider>
                    </ThemeProvider>
                </LangProvider>
            </CacheProvider>
        </>
    );
}
