import { bornCodec, Email, nullable, trimmedStringCodec } from "./common";
import * as t from "io-ts";
import { NonEmptyString, UUID } from "io-ts-types";
import { messages } from "../utils/i18n/i18n";
import { table, TableRowT } from "../utils/pg-ts/pg-ts";
import { identity, pipe } from "fp-ts/function";
import { array, either } from "fp-ts";

export type User = {
    accounts: Array<Account>;
    // athleteIds: Array<Athlete> links to different athlete sources (jesenicky snek, bozivybeh, odlozil...)
};

type Nationality = "cze" | "svk" | "pol" | "deu" | "aut"; // https://www.iban.com/country-codes

export interface Athlete {
    nationality: Nationality;
    born: Date;
}

export type Account = {
    type: "email";
    emailAddress: Email;
    // TODO un/verified,
};

export const sexMessages = messages(
    {
        M: "male",
        F: "female",
    },
    {
        cs: {
            M: "muž",
            F: "žena",
        },
    }
);

// TODO do not use, remove
export const sex = {
    M: "muž",
    F: "žena",
};

const sexCaseSensitiveCodec = t.keyof(sexMessages.en);
export type Sex = t.TypeOf<typeof sexCaseSensitiveCodec>;
export const sexCodec = new t.Type<Sex>(
    "Sex",
    sexCaseSensitiveCodec.is,
    (u, ctx) =>
        pipe(
            t.string.validate(u, ctx),
            either.chain((s) =>
                sexCaseSensitiveCodec.validate(s.toUpperCase(), ctx)
            )
        ),
    identity
);

// TODO remove
export const category = (sex: Sex, born: number) => {
    if (born >= 2010) {
        return sex === "M" ? "kluci" : "holky";
    } else if (born >= 2006) {
        return sex === "M" ? "dorostenci" : "dorostenky";
    } else if (born >= 1972) {
        return sex === "M" ? "muži" : "ženy";
    } else {
        return sex === "M" ? "veteráni" : "veteránky";
    }
};

const nameSegmentRegExp = "\\S+";
export const fullnamePattern = /^\S+((\s+\S*)+)$/;
// export const fullnamePattern = `/^${nameSegmentRegExp}(\\s${nameSegmentRegExp})+$/`;

interface NameSegment {
    readonly NameSegment: unique symbol;
}
export const nameSegmentCodec = t.brand(
    t.string,
    (s): s is t.Branded<string, NameSegment> =>
        new RegExp(nameSegmentRegExp).test(s),
    "NameSegment"
);

interface FullNameBrand {
    readonly FullName: unique symbol;
}
export const fullNameCodec = t.brand(
    trimmedStringCodec,
    (s): s is t.Branded<string, FullNameBrand> =>
        new RegExp(fullnamePattern).test(s),
    "FullName"
);
export type FullName = t.TypeOf<typeof fullNameCodec>;

export const switchForenameAndSurname = (name: FullName) =>
    pipe(name.split(" "), array.rotate(1), (s) => s.join(" ") as FullName);

export const athleteTable = table("athletes", {
    id: UUID,
    account_id: UUID,
    name: fullNameCodec,
    born: bornCodec,
    sex: sexCodec,
    club: nullable(NonEmptyString), // TODO tmp solution
    city: nullable(NonEmptyString),
});

export type AthleteRow = TableRowT<typeof athleteTable>;

// TODO partial is not the case, you want to validate one field by another, if one field failed, rest of the fields are filled
export const wellKnownUserMetadataCodec = t.partial({
    full_name: t.string,
    name: t.string,
});

export const athleteMessages = messages(
    {
        name: "Firstname Lastname",
        born: "Year of birth",
        sex: "Sex",
        club: "Club",
        city: "City",
    },
    {
        cs: {
            name: "Jméno Příjmení",
            born: "Rok narození",
            sex: "Pohlaví",
            club: "Klub",
            city: "Město",
        },
    }
);
