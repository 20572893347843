import * as React from "react";

import Script from "next/script";
import { pipe } from "fp-ts/lib/function";
import { publicEnv } from "../utils/env/env";
import { either } from "fp-ts";
import { constNull } from "fp-ts/function";

export const MicrosoftClarity = () => {
    return pipe(
        publicEnv,
        either.fold(constNull, (env) => (
            <Script
                id="microsoft-clarity-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${env.NEXT_PUBLIC_MICROSOFT_CLARITY}");`,
                }}
            />
        ))
    );
};
