import * as t from "io-ts";
import { positiveIntCodec } from "./common";

export const clubScoringCodec = t.type(
    {
        scoringMembersCount: positiveIntCodec,
        scoringMethod: t.union([t.literal("timeSum"), t.literal("rankSum")]),
    },
    "ClubScoring"
);

export type ClubScoring = t.TypeOf<typeof clubScoringCodec>;
