import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SupabaseClient, User } from "@supabase/supabase-js";
import { Option } from "fp-ts/Option";
import { either, option, record, task } from "fp-ts";
import { sequenceS } from "fp-ts/Apply";
import { constNull, flow, pipe } from "fp-ts/function";
import { supabaseUserCodec, useUser } from "../../utils/supabase/useUser";
import { executeSupabaseQuery } from "../../utils/supabase/useQuery";
import { runTask } from "../../utils/fp";
import { GlobalAlert, useGlobalAlertsSubject } from "../common/GlobalAlerts";
import {
    passwordInputCodec,
    passwordInputCodecMessages,
    passwordInputMinLengthRule,
} from "../../domain/sign";
import { trimmedStringCodec } from "../../domain/common";
import * as t from "io-ts";
import { useForm } from "../form/form";
import { PasswordInput } from "../form/PasswordInput";
import { useTranslation } from "../../utils/i18n/lang";
import { messages } from "../../utils/i18n/i18n";
import { PasswordManagerUsernameInput } from "../form/PasswordManagerUsernameInput";
import { reportError } from "../../utils/reporting";

interface Props {
    supabase: SupabaseClient;
}

type AccessToken = string;
// TODO test workflow in chrome password manager (now is created entry with empty username)
// TODO expired token
// TODO already clicked link
/**
 http://localhost:3000/event/attend#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjI2NzAxMTcxLCJzdWIiOiIxYTI0N2YzZS00NDFjLTQyYWMtYTFhNS05MTA0MTlkNDY2ZDciLCJlbWFpbCI6InBhdHJpay5wb21wZUBnbWFpbC5jb20iLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJnb29nbGUifSwidXNlcl9tZXRhZGF0YSI6eyJhdmF0YXJfdXJsIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2pEYUxWaEFueUNWTFVKVF84MzlRZVNKRFdwamFKRXUzVC0ycFJOPXM5Ni1jIiwiZnVsbF9uYW1lIjoiUGF0cmlrIFBvbXBlIn0sInJvbGUiOiJhdXRoZW50aWNhdGVkIn0.MSJyjsQ5KkEl0wbFCN4rq-BIovI_IoJdsiAWFIOE1Ww&expires_in=3600&refresh_token=--W_brtaBawYpohE4l6KOQ&token_type=bearer&type=recovery
 **/
const PasswordChangeModal = (p: Props) => {
    const [accessToken, setAccessToken] = React.useState<Option<AccessToken>>(
        option.none
    );

    const user = useUser();

    React.useEffect(() => {
        const hashParams = new URLSearchParams(window.location.hash.substr(1));
        const accessTokenKey = "access_token";

        if (
            hashParams.get("type") === "recovery" &&
            hashParams.has(accessTokenKey) &&
            hashParams.has("expires_in") &&
            hashParams.has("refresh_token") &&
            hashParams.has("token_type")
        ) {
            setAccessToken(option.fromNullable(hashParams.get(accessTokenKey)));
        }
    }, []);

    return pipe(
        sequenceS(option.option)({
            user,
            accessToken,
        }),
        option.fold(constNull, (ctx) => (
            <PasswordChangeForm
                supabase={p.supabase}
                user={ctx.user}
                accessToken={ctx.accessToken}
                handleClose={() => {
                    setAccessToken(option.none);
                }}
            />
        ))
    );
};

export default PasswordChangeModal;

interface PasswordChangeProps {
    user: User;
    accessToken: AccessToken;
    handleClose: () => void;
    supabase: SupabaseClient;
}

const PasswordChangeForm = (p: PasswordChangeProps) => {
    const m = useTranslation(msg);
    const [loading, setLoading] = React.useState(false);
    const form = useForm(formDef, (data) => {
        setLoading(true);

        if (data.newPassword !== data.newPasswordConfirm) {
            form.formHooks.setError("newPasswordConfirm", {
                message: m.confirmNotEqual,
            });
            setLoading(false);
        } else {
            pipe(
                executeSupabaseQuery(
                    "passwordChange",
                    supabaseUserCodec,
                    (supabase) =>
                        supabase.auth.api.updateUser(p.accessToken, {
                            password: data.newPassword,
                        }),
                    p.supabase
                ),
                task.map(
                    flow(
                        either.fold(
                            (error): GlobalAlert => {
                                reportError("passwordChangeFailed", error);
                                return {
                                    severity: "error",
                                    message: m.changeFailed,
                                };
                            },
                            (): GlobalAlert => ({
                                severity: "success",
                                message: m.changeSuccess,
                            })
                        ),
                        (alert) => {
                            alerts$.next(alert);
                            setLoading(false);
                            p.handleClose();
                        }
                    )
                ),
                runTask
            );
        }
    });

    const alerts$ = useGlobalAlertsSubject();

    return (
        <Dialog open={true} onClose={p.handleClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>Změna hesla</DialogTitle>
            <form {...form.formProps}>
                <DialogContent>
                    <Grid container direction={"column"} spacing={3}>
                        <Grid item>
                            <PasswordInput
                                autoComplete={"new-password"}
                                label={m.newPassword}
                                {...form.req.newPassword}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <PasswordInput
                                autoComplete={"new-password"}
                                fullWidth
                                label={m.newPasswordConfirm}
                                {...form.req.newPasswordConfirm}
                            />
                        </Grid>
                    </Grid>
                    <PasswordManagerUsernameInput value={p.user.email} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={p.handleClose} color="primary">
                        Storno
                    </Button>
                    <LoadingButton type={"submit"} loading={loading}>
                        Uložit
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const msg = messages(
    {
        newPassword: "New password",
        newPasswordConfirm: "Confirm new password",
        confirmNotEqual: "Passwords are not equal",
        changeFailed: "Passwords change failed",
        changeSuccess: "Password has been updated",
        forgottenPassword: "Forgot password?",
        changeSuccessful: "Password has been successfully changed",
    },
    {
        cs: {
            newPassword: "Nové heslo",
            newPasswordConfirm: "Nové heslo znovu",
            confirmNotEqual: "Hesla nejsou shodná",
            changeFailed: "Změna hesla selhala",
            changeSuccess: "Heslo bylo změněno",
            forgottenPassword: "Zapomněli jste heslo?",
            changeSuccessful: "Heslo bylo úspěšně změněno",
        },
    }
);
const formDef = {
    required: {
        newPassword: {
            codec: trimmedStringCodec.pipe(passwordInputCodec), // trim should be used in login form as well!
            messages: passwordInputCodecMessages,
            helperText: pipe(
                passwordInputCodecMessages,
                record.map((msg) => msg())
            ),
        },
        newPasswordConfirm: { codec: trimmedStringCodec.pipe(t.string) },
    },
    optional: {},
};
