import * as React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { Subject } from "rxjs";
import { Option } from "fp-ts/Option";
import { array, option } from "fp-ts";
import { flow, identity, pipe } from "fp-ts/function";

export interface GlobalAlert {
    severity: AlertColor;
    message: React.ReactNode;
}

type GlobalAlertsSubject = Subject<GlobalAlert>;

export const GlobalAlertsContext = React.createContext<
    Option<GlobalAlertsSubject>
>(option.none);
type Props = {
    children: React.ReactNode;
};
export const GlobalAlertsProvider = (p: Props) => {
    const submit$: GlobalAlertsSubject = new Subject();

    return (
        <GlobalAlertsContext.Provider value={option.some(submit$)}>
            {p.children}
        </GlobalAlertsContext.Provider>
    );
};

export const useGlobalAlertsSubject = () =>
    pipe(
        React.useContext(GlobalAlertsContext),
        option.fold(() => {
            throw new Error(
                `useGlobalAlertsSubject must be used within ${GlobalAlertsProvider.name}`
            );
        }, identity)
    );

const useGlobalAlerts = (onAlert: (alert: GlobalAlert) => void) => {
    const alerts$ = useGlobalAlertsSubject();

    React.useEffect(() => {
        const subscription = alerts$.subscribe(onAlert);
        return () => subscription.unsubscribe();
    }, [onAlert]);
};

export const GlobalAlerts = () => {
    const [alerts, setAlerts] = React.useState<Array<GlobalAlert>>([]);
    const [open, setOpen] = React.useState(false);

    useGlobalAlerts((alert) => {
        setAlerts(array.append(alert));
        setOpen(true);
    });

    const onClose = () => {
        setOpen(false);
    };

    const onExited = () => {
        setAlerts(
            flow(
                array.tail,
                option.fold(() => [], identity),
                (tail) => {
                    setOpen(array.isNonEmpty(tail));
                    return tail;
                }
            )
        );
    };

    const toDisplay = array.head(alerts);

    return (
        <Snackbar
            open={open && option.isSome(toDisplay)}
            autoHideDuration={10000}
            TransitionProps={{ onExited }}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            {pipe(
                toDisplay,
                option.fold(
                    () => <></>,
                    (alert) => (
                        <Alert onClose={onClose} severity={alert.severity}>
                            {alert.message}
                        </Alert>
                    )
                )
            )}
        </Snackbar>
    );
};
