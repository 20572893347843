import { useSupabase } from "./useSupabase";
import { flow, identity, pipe } from "fp-ts/function";
import { SupabaseClient } from "@supabase/supabase-js";
import { either, option, record, task, taskEither } from "fp-ts";
import useSWR from "swr";
import * as t from "io-ts";
import { errorsToMessage } from "../fp";
import { optionFromNullable } from "io-ts-types";
import { Either } from "fp-ts/Either";
import { KeyedMutator, SWRConfiguration } from "swr/dist/types";
import * as Sentry from "@sentry/browser";
import { QueryError, QueryState } from "./QueryState";
import { useMemo } from "react";
import { imagesTable } from "../../domain/event";

class QueryErrorThrowable extends Error {
    constructor(message: string, public kind: QueryError["kind"]) {
        super(message);
    }
}

type SupabaseQuery = (supabase: SupabaseClient) => PromiseLike<unknown>;
// hacky fallback which should never be executed (satisfy typings), usage related to skip query in swr
export const skipQuery = (): SupabaseQuery => () => Promise.resolve();

// TODO unify with use api?
// TODO sql error is marked as network error right now... checkit
// TODO error JWT expired => redirect to login page
export const useQuery = <
    DataCodec extends t.Mixed,
    Query extends SupabaseQuery
>(
    id: string,
    query: Query,
    dataCodec: DataCodec,
    swrConfig?: SWRConfiguration<t.TypeOf<DataCodec>, Error>,
    shouldSkip: boolean = false // TODO replace with making query arg optional?
) => {
    type Data = t.TypeOf<DataCodec>;
    const supabase = useSupabase();

    const { data, error, mutate, ...rest } = useSWR(
        shouldSkip ? null : id,
        async () =>
            pipe(
                executeSupabaseQuery(id, dataCodec, query, supabase),
                async (task) => {
                    const res = await task();
                    return pipe(
                        res,
                        either.fold((e) => {
                            throw new QueryErrorThrowable(e.message, e.kind);
                        }, identity)
                    );
                }
            ),
        swrConfig
    );

    const state: QueryState<Data> = useMemo(
        () =>
            pipe(
                data,
                option.fromNullable,
                option.fold(
                    () =>
                        pipe(
                            error,
                            option.fromNullable,
                            option.fold(
                                () => ({
                                    type: "loading",
                                }),
                                (error): QueryState<Data> => ({
                                    type: "error",
                                    kind: "network",
                                    message: String(error),
                                })
                            )
                        ),
                    (data): QueryState<Data> => ({
                        type: "completed",
                        data,
                    })
                )
            ),
        [data, error]
    );

    return {
        ...rest,
        mutate: mutate as KeyedMutator<Data>,
        state,
    };
};

const queryCodec = t.type(
    {
        body: t.unknown, // TODO
        count: optionFromNullable(t.number),
        data: t.unknown, // TODO data codec
        error: optionFromNullable(
            t.type(
                {
                    hint: optionFromNullable(t.unknown), // TODO
                    details: optionFromNullable(t.unknown), // TODO
                    status: optionFromNullable(t.number),
                    code: optionFromNullable(t.string),
                    message: t.string,
                },
                "SupabaseError"
            )
        ),
    },
    "QueryCodec"
);

export const rowCodecToFields = <Codec extends t.TypeC<any>>(codec: Codec) =>
    pipe(codec.props, record.keys, (keys) => keys.join(","));

// interface Query<Data> {
//     dataCodec: t.Type<Data>;
//     query: SupabaseQuery;
// }
// type TypeOfQueryData<Type> = Type extends Query<infer X> ? X : never;
// export interface Queries {
//     [key: string]: Query<any>;
// }
// export const executeSupabaseQueries = <Q extends Queries>(
//     id: string,
//     supabase: SupabaseClient,
//     queries: Q
// ): TaskEither<
//     QueryError,
//     {
//         [Property in keyof Q]: TypeOfQueryData<Q[Property]>;
//     }
// > =>
//     pipe(
//         queries,
//         record.mapWithIndex((key, q) =>
//             executeSupabaseQuery(`${id}_${key}`, q.dataCodec, q.query, supabase)
//         ),
//         sequenceS(taskEither.taskEither)
//     );

export const executeSupabaseQuery = <DataCodec extends t.Mixed>(
    id: string,
    dataCodec: DataCodec,
    query: SupabaseQuery,
    supabase: SupabaseClient
) => {
    type Data = t.TypeOf<DataCodec>;

    return pipe(
        taskEither.tryCatch(
            () => query(supabase) as unknown as Promise<any>,
            (e): QueryError => ({
                kind: "network",
                message: String(e),
            })
        ),
        task.map((res) => {
            console.log(id, JSON.stringify(res));

            if (either.isRight(res) && res.right.error instanceof Error) {
                const e: QueryError = {
                    kind: "network",
                    message: res.right.error.toString(),
                };
                return either.left(e);
            } else {
                return res;
            }
        }),
        task.map(
            either.chain(
                flow(
                    queryCodec.decode,
                    either.mapLeft(
                        flow(
                            errorsToMessage,
                            (message): QueryError => ({
                                kind: "unexpectedResponseFormat",
                                message,
                            })
                        )
                    ),
                    either.chain(
                        (r): Either<QueryError, Data> =>
                            pipe(
                                r.error,
                                option.fold(
                                    () =>
                                        pipe(
                                            r.data,
                                            dataCodec.decode,
                                            either.mapLeft(
                                                (errors): QueryError => ({
                                                    kind: "unexpectedDataFormat",
                                                    message:
                                                        errorsToMessage(errors),
                                                })
                                            )
                                        ),
                                    (e): Either<QueryError, Array<Data>> =>
                                        either.left({
                                            kind: "dbError",
                                            message: e.message,
                                        })
                                )
                            )
                    )
                )
            )
        ),
        taskEither.mapLeft((e) => {
            Sentry.captureMessage(`${id}: ${e.kind} - ${e.message}`);
            return e;
        })
    );
};

// TODO will be strongly typed with pg-ts
export const rpc = <
    DataCodec extends t.Mixed,
    Params extends Record<string, unknown>
>(
    procedure: string,
    params: Params,
    dataCodec: DataCodec,
    supabase: SupabaseClient
) =>
    executeSupabaseQuery(
        procedure,
        dataCodec,
        () => supabase.rpc(procedure, params),
        supabase
    );

export const queryErrorToDefaultMessage = (e: QueryError) => {
    return `Došlo k chybě: ${e.message}`;
};

export const filePublicUrl = (
    bucket: string,
    filename: string,
    supabase: SupabaseClient
) =>
    pipe(
        executeSupabaseQuery(
            "file_public_url",
            t.type({
                publicURL: imagesTable.columns.url,
            }),
            (supabase) =>
                Promise.resolve(
                    // this api call is not async, but executeSupabaseQuery requires it
                    supabase.storage.from(bucket).getPublicUrl(filename)
                ),
            supabase
        ),
        taskEither.map((res) => res.publicURL)
    );
