import { Distance } from "./common";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

interface DistanceFormat extends Pick<Intl.NumberFormat, "format"> {}
const maximumFractionDigitsForKm = 2;

export const distanceFormat = (locale: string) => {
    const formatterKm = pipe(
        option.tryCatch(
            (): DistanceFormat =>
                new Intl.NumberFormat(locale, {
                    style: "unit", // not supported on some apple systems
                    unit: "kilometer",
                    maximumFractionDigits: maximumFractionDigitsForKm,
                })
        ),
        option.getOrElse(() => kmFormatterFallback(locale))
    );

    const formatterMeter = pipe(
        option.tryCatch(
            () =>
                new Intl.NumberFormat(locale, {
                    style: "unit", // not supported on some apple systems
                    unit: "meter",
                })
        ),
        option.getOrElse(() => meterFormatterFallback(locale))
    );

    return (distance: Distance) => {
        if (distance > 1000) {
            return formatterKm.format(distance / 1000);
        } else {
            return formatterMeter.format(distance);
        }
    };
};

export const kmFormatterFallback = (locale: string): DistanceFormat => {
    const numberFormat = new Intl.NumberFormat(locale, {
        style: "decimal",
        maximumFractionDigits: maximumFractionDigitsForKm,
    });

    return {
        format: (kilometers: number) => `${numberFormat.format(kilometers)} km`,
    };
};

export const meterFormatterFallback = (locale: string): DistanceFormat => {
    const numberFormat = new Intl.NumberFormat(locale, {
        style: "decimal",
        maximumFractionDigits: 0,
    });

    return {
        format: (meters: number) => `${numberFormat.format(meters)} m`,
    };
};
