import { ageCodec, commonIdCodec, nullable, Year } from "./common";
import * as t from "io-ts";
import { table, TableRowT } from "../utils/pg-ts/pg-ts";
import { NonEmptyString } from "io-ts-types";
import { AthleteRow, sexCodec } from "./user";
import { organizationIdCodec } from "./organization";
import { number, option, ord } from "fp-ts";
import { pipe } from "fp-ts/function";
import { messages, Translation } from "../utils/i18n/i18n";
import { contramap, Ord } from "fp-ts/Ord";
import { concatAll } from "fp-ts/Monoid";

const categoryIdCodec = commonIdCodec;
export type CategoryId = t.TypeOf<typeof categoryIdCodec>;
export const categoriesTable = table("categories", {
    id: categoryIdCodec,
    title: NonEmptyString, // TODO length limit
    description: nullable(NonEmptyString), // TODO length limit
    sex: sexCodec,
    age_gte: nullable(ageCodec), // todo pg int4range?
    age_lte: nullable(ageCodec),
    organization_id: organizationIdCodec,
});

const inRange = (from: number | null, to: number | null, value: number) =>
    (from == null || from <= value) && (to == null || to >= value);

// TODO categories independent on sex? age?
export const isCategoryForAthlete =
    (athlete: Pick<AthleteRow, "born" | "sex">, eventYear: Year) =>
    (
        category: Pick<
            TableRowT<typeof categoriesTable>,
            "age_gte" | "age_lte" | "sex"
        >
    ) => {
        const age = eventYear - athlete.born;

        return (
            category.sex === athlete.sex &&
            inRange(category.age_gte, category.age_lte, age)
        );
    };

const ageFormatter = (ageNullable: Nullable<number>, unbound: string) =>
    pipe(
        ageNullable,
        option.fromNullable,
        option.map((age) => `${age}`),
        option.getOrElse(() => unbound)
    );

export const categoryMessages = messages(
    {
        years: "years",
        andYounger: (year: number) => `${year} and younger`,
        andOlder: (year: number) => `${year} and older`,
        andMore: "and more",
        title: "title",
        sex: "sex",
        description: "description",
        age_gte: "age greater then equal",
        age_lte: "age less then equal",
    },
    {
        cs: {
            years: "let",
            andYounger: (year: number) => `${year} a mladší`,
            andOlder: (year: number) => `${year} a starší`,
            andMore: "a více",
            title: "název",
            sex: "pohlaví",
            description: "popis",
            age_gte: "věk větší rovno",
            age_lte: "věk menší rovno",
        },
    }
);

const yearFromAge = (ageNullable: Nullable<number>, currentYar: number) =>
    pipe(
        ageNullable,
        option.fromNullable,
        option.map((ageLte) => currentYar - ageLte)
    );

export const categoryLabel = (
    category: Pick<
        TableRowT<typeof categoriesTable>,
        "description" | "title" | "age_lte" | "age_gte"
    >,
    categoryMsg: Translation<typeof categoryMessages>,
    raceYear?: number
) => {
    const descriptionStr = pipe(
        category.description,
        option.fromNullable,
        option.fold(
            () => "",
            (description) => ` - ${description}`
        )
    );

    const ageStr = `${ageFormatter(category.age_gte, "0")}${
        category.age_lte == null ? " " : " - "
    }${ageFormatter(category.age_lte, categoryMsg.andMore)} ${
        categoryMsg.years
    }`;

    const bornStr = pipe(
        raceYear,
        option.fromNullable,
        option.fold(
            () => "",
            (year) => {
                const bornFrom = yearFromAge(category.age_lte, year);
                const bornTo = yearFromAge(category.age_gte, year);
                const from = pipe(
                    bornFrom,
                    option.map((from) =>
                        option.isSome(bornTo)
                            ? `${from}`
                            : categoryMsg.andYounger(from)
                    )
                );

                const to = pipe(
                    bornTo,
                    option.map((to) =>
                        option.isSome(bornFrom)
                            ? `- ${to}`
                            : categoryMsg.andOlder(to)
                    )
                );

                if (option.isSome(from) || option.isSome(to)) {
                    return ` (${pipe(
                        from,
                        option.getOrElse(() => "")
                    )} ${pipe(
                        to,
                        option.getOrElse(() => "")
                    )})`;
                } else {
                    return "";
                }
            }
        )
    );

    return `${category.title}${descriptionStr}, ${ageStr}${bornStr}`;
};

type WithAge = Pick<TableRowT<typeof categoriesTable>, "age_gte" | "age_lte">;

const categoryOrdByAgeGte: Ord<WithAge> = contramap((category: WithAge) =>
    option.fromNullable(category.age_gte)
)(option.getOrd(number.Ord));

const categoryOrdByAgeLte: Ord<WithAge> = contramap((category: WithAge) =>
    option.fromNullable(category.age_lte)
)(option.getOrd(number.Ord));

export const categoryOrdByAge = concatAll(ord.getMonoid<WithAge>())([
    categoryOrdByAgeGte,
    categoryOrdByAgeLte,
]);
