import { createTheme, ThemeOptions } from "@mui/material/styles";
import { csCZ } from "@mui/material/locale";
import { csCZ as dataGridCsCz } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";

// Module Augmentation - https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
declare module "@mui/material/styles" {
    interface Theme {
        surfaceColor: {
            topLevel: string;
        };
        borderColor: string;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        surfaceColor?: {
            topLevel?: string;
        };
        borderColor?: string;
    }
}

const themeBase = createTheme({
    surfaceColor: {
        topLevel: grey["50"],
    },
    borderColor: grey["300"],
});

export const createXathloTheme = (options?: ThemeOptions) =>
    createTheme(
        {
            ...themeBase,
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                        dt {
                          font-weight: bolder;
                        }
                      `,
                },
                MuiButton: {
                    defaultProps: {
                        size: "small",
                    },
                },
                MuiFormControl: {
                    defaultProps: {
                        size: "small",
                    },
                },
                MuiInputBase: {
                    defaultProps: {
                        size: "small",
                    },
                    styleOverrides: {
                        root: {
                            backgroundColor: themeBase.palette.background.paper,
                        },
                    },
                },
                MuiPaper: {
                    defaultProps: {
                        variant: "outlined",
                    },
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            boxShadow: themeBase.shadows[2],
                        },
                    },
                },
                MuiList: {
                    defaultProps: {
                        dense: true,
                    },
                },
                MuiTable: {
                    defaultProps: {
                        size: "small",
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            padding: "6px", // Default padding
                            [themeBase.breakpoints.up("sm")]: {
                                padding: "2px", // Padding for small screens and up
                            },
                            // [themeBase.breakpoints.up("lg")]: {
                            //     padding: "24px", // Padding for large screens and up
                            // },
                        },
                    },
                },
                MuiAutocomplete: {
                    defaultProps: {
                        size: "small",
                    },
                },
                MuiTabs: {
                    defaultProps: {
                        variant: "scrollable",
                        allowScrollButtonsMobile: true,
                    },
                },
            },
            ...options,
        },
        csCZ, // TODO i18n
        dataGridCsCz // TODO i18n
    );

const light = createXathloTheme();
export default light;
