import { constFalse, constTrue, flow, identity, pipe } from "fp-ts/function";
import * as t from "io-ts";
import { Mixed } from "io-ts";
import { either, option } from "fp-ts";
import { date, JsonFromString, NonEmptyString } from "io-ts-types";
import * as O from "fp-ts/Option";
import { formatISO, parseISO } from "date-fns";
import { MDXRemoteSerializeResult } from "next-mdx-remote";
import isURL from "validator/lib/isURL";
import isEmail from "validator/lib/isEmail";

const isTrimmedString = (s: string) => s === s.trim();

// TODO only for forms?
export const trimmedStringCodec = new t.Type<string>(
    "TrimmedString",
    (s): s is string => t.string.is(s) && isTrimmedString(s),
    (u, ctx) =>
        pipe(
            t.string.validate(u, ctx),
            either.chain((s) => t.success(s.trim()))
        ),
    identity
);

const condenseSpaces = (text: string) => text.replace(/\s\s+/g, " ");
const isCondensedString = (s: string) => s === condenseSpaces(s);
export const condensedSpacesStringCodec = new t.Type<string>(
    "CondensedSpacesString",
    (s): s is string => t.string.is(s) && isCondensedString(s),
    (u, ctx) =>
        pipe(
            t.string.validate(u, ctx),
            either.chain((s) => t.success(condenseSpaces(s)))
        ),
    identity
);

// TODO only for forms?
export const nonEmptyStringOrNullCodec = <Codec extends t.Mixed>(
    codec: Codec
) => {
    type NonEmptyStringOrNull = t.TypeOf<typeof codec> | null;

    return new t.Type<NonEmptyStringOrNull, string>(
        "NonEmptyStringOrNull",
        (u): u is NonEmptyStringOrNull => t.null.is(u) || codec.is(u),
        (u, ctx) =>
            t.null.is(u)
                ? t.success(null)
                : pipe(
                      t.string.validate(u, ctx),
                      either.chain((s) =>
                          s.length === 0
                              ? t.success(null)
                              : codec.validate(s, ctx)
                      )
                  ),
        (v) => (v === null ? "" : codec.encode(v))
    );
};

export const commonIdCodec = t.number;

export interface PositiveBrand {
    readonly Positive: unique symbol;
}
export const positiveNumberCodec = t.brand(
    t.number,
    (n): n is t.Branded<number, PositiveBrand> => n >= 0,
    "Positive"
);

export const positiveIntCodec = t.intersection(
    [t.Int, positiveNumberCodec],
    "PositiveInteger"
);
export type PositiveInt = t.TypeOf<typeof positiveIntCodec>;

export interface EmailBrand {
    readonly Email: unique symbol;
}

export const emailCodec = t.brand(
    t.string,
    (s): s is t.Branded<string, EmailBrand> => isEmail(s),
    "Email"
);
export type Email = t.TypeOf<typeof emailCodec>;

// eliminates Invalid date instances
interface ValidDateBrand {
    readonly ValidDate: unique symbol;
}
const validDatePredicate = (d: Date): d is t.Branded<Date, ValidDateBrand> =>
    !isNaN(d.getTime());
export const validDateCodec = t.brand(date, validDatePredicate, "ValidDate");

interface LocalDateStringBrand {
    readonly LocalDateString: unique symbol;
}
export type LocalDateString = t.Branded<string, LocalDateStringBrand>;
export const localDateString = (date: Date): O.Option<LocalDateString> =>
    option.tryCatch(
        () => formatISO(date, { representation: "date" }) as LocalDateString
    );
export const localDateStringCodec = t.brand(
    t.string,
    (s): s is LocalDateString =>
        pipe(
            s,
            parseISO,
            localDateString,
            option.fold(
                () => {
                    console.error(
                        "localDateStringCodec: localDateString failure",
                        s
                    );
                    return false;
                },
                (parsed) => {
                    const res = parsed === s;
                    if (!res) {
                        console.error(
                            "localDateStringCodec: predicate failure",
                            res,
                            parsed,
                            s
                        );
                    }
                    return res;
                }
            )
        ),
    "LocalDateString"
);

export const localDateStringFromDateCodec = new t.Type<LocalDateString, Date>(
    "LocalDateStringFromDate",
    localDateStringCodec.is,
    (u, ctx) =>
        pipe(
            validDateCodec.validate(u, ctx),
            either.chain(
                flow(
                    localDateString,
                    option.fold(() => t.failure(u, ctx), t.success)
                )
            )
        ),
    parseISO
);

// similar to io-ts-types DateFromISOString, but keeps string
interface ValidIsoDateStringBrand {
    readonly ValidIsoDateString: unique symbol;
}
export const validIsoDateStringCodec = t.brand(
    t.string,
    (s): s is t.Branded<string, ValidIsoDateStringBrand> =>
        pipe(
            option.tryCatch(() => parseISO(s)),
            option.filter(validDatePredicate),
            option.fold(constFalse, constTrue)
        ),
    "ValidIsoDateString"
);
export type ValidIsoDateString = t.TypeOf<typeof validIsoDateStringCodec>;

export const isoDateString = (date: Date): O.Option<ValidIsoDateString> =>
    option.tryCatch(() => formatISO(date) as ValidIsoDateString);

export const validIsoDateStringFromDateCodec = new t.Type<
    ValidIsoDateString,
    Date
>(
    "ValidIsoDateStringFromDate",
    validIsoDateStringCodec.is,
    (u, ctx) =>
        pipe(
            validDateCodec.validate(u, ctx),
            either.chain(
                flow(
                    isoDateString,
                    option.fold(() => t.failure(u, ctx), t.success)
                )
            )
        ),
    parseISO
);

export type Year = number;
// TODO check usages, probably event date should always exist in the usage, or at race start is more accurate?
export const yearFromLocalDate = (date: LocalDateString): Year =>
    pipe(date, localDateStringFromDateCodec.encode, (date) =>
        date.getFullYear()
    );

export interface PgIntegerBrand {
    readonly PgInteger: unique symbol;
}
export const pgIntegerCodec = t.brand(
    t.Int,
    (n): n is t.Branded<t.Int, PgIntegerBrand> =>
        n >= -2147483648 && n <= 2147483647,
    "PgInteger"
);
export type PgInteger = t.TypeOf<typeof pgIntegerCodec>;
export interface PgSmallintBrand {
    readonly PgSmallint: unique symbol;
}
export const pgSmallintCodec = t.brand(
    t.Int,
    (n): n is t.Branded<t.Int, PgSmallintBrand> => n >= -32768 && n <= 32767,
    "PgSmallint"
);

export const distanceCodec = t.intersection(
    [pgIntegerCodec, positiveNumberCodec],
    "Distance"
); // distance in meters

export type Distance = t.TypeOf<typeof distanceCodec>;
export const distanceAdd = (a: Distance, b: Distance) => (a + b) as Distance;

export const rectSizeCodec = t.type(
    {
        height: t.number,
        width: t.number,
    },
    "RectSize"
);

export type RectSize = t.TypeOf<typeof rectSizeCodec>;
interface MdxInputBrand {
    readonly MdxInput: unique symbol;
}
export const mdxInputCodec = t.brand(
    NonEmptyString,
    (s): s is t.Branded<NonEmptyString, MdxInputBrand> => true,
    "MdxInput"
);
export type MdxInput = t.TypeOf<typeof mdxInputCodec>;
export type MdxContent = MDXRemoteSerializeResult;

export const nullable = <T extends Mixed>(codec: T) =>
    t.union([codec, t.null], codec.name + "_nullable");

interface BornBrand {
    readonly Born: unique symbol;
}
export const bornCodec = t.brand(
    t.number,
    (n): n is t.Branded<number, BornBrand> => n > 1900 && n < 3000,
    "Born"
);
export type Born = t.TypeOf<typeof bornCodec>;

export const ageCodec = positiveIntCodec;

interface UrlBrand {
    readonly Url: unique symbol;
}
export const urlCodec = t.brand(
    t.string,
    (s): s is t.Branded<string, UrlBrand> => isURL(s),
    "Url"
);

export const timeStampRangeCodec = t.string.pipe(
    JsonFromString.pipe(
        t.tuple([validIsoDateStringCodec, validIsoDateStringCodec])
    ),
    "TimeStampRange"
);
export type TimestampRange = t.TypeOf<typeof timeStampRangeCodec>;

export interface SearchTermBrand {
    readonly SearchTerm: unique symbol;
}
export const createSearchTermCodec = (minLength: number) =>
    t.brand(
        t.string,
        (s): s is t.Branded<string, SearchTermBrand> =>
            s.trim().length >= minLength,
        "SearchTerm"
    );
export type SearchTerm = t.TypeOf<ReturnType<typeof createSearchTermCodec>>;
