import * as t from "io-ts";
import { array, either } from "fp-ts";
import { flow, pipe } from "fp-ts/function";
import { domainRootLevelsCodec } from "../subdomain/subdomain";
import { errorsToMessage, pick } from "../fp";
import { NumberFromString } from "io-ts-types";

const envFields = {
    NEXT_PUBLIC_DOMAIN_ROOT_LEVELS: domainRootLevelsCodec,
    NEXT_PUBLIC_COMMIT_SHA: t.union([t.string, t.undefined]),
    NODE_ENV: t.union(
        [t.literal("production"), t.literal("development"), t.literal("test")],
        "NODE_ENV"
    ),

    SENTRY_ORG: t.string,
    SENTRY_PROJECT: t.string,
    SENTRY_AUTH_TOKEN: t.string,
    NEXT_PUBLIC_SENTRY_DSN: t.string,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: t.string,
    NEXT_PUBLIC_SENTRY_SAMPLE_RATE: NumberFromString,

    SUPABASE_SERVICE_KEY: t.string,
    NEXT_PUBLIC_SUPABASE_URL: t.string,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: t.string,

    NEXT_PUBLIC_MICROSOFT_CLARITY: t.string,

    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: t.string,
    STRIPE_SECRET_KEY: t.string,
    STRIPE_WEBHOOK_SECRET: t.string,

    RESEND_API_KEY: t.string,
};

const publicEnvKeys: Array<keyof typeof envFields> = [
    "NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY",
    "NEXT_PUBLIC_MICROSOFT_CLARITY",
    "NEXT_PUBLIC_SENTRY_DSN",
    "NEXT_PUBLIC_SENTRY_ENVIRONMENT",
    "NEXT_PUBLIC_SUPABASE_URL",
    "NEXT_PUBLIC_SUPABASE_ANON_KEY",
    "NEXT_PUBLIC_DOMAIN_ROOT_LEVELS",
    "NEXT_PUBLIC_COMMIT_SHA",
    "NODE_ENV",
];

export const envCodec = t.type(envFields, "publicEnv");

export const publicEnvCodec = pipe(envFields, pick(...publicEnvKeys), t.type);

const envInput = {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_COMMIT_SHA: process.env.NEXT_PUBLIC_COMMIT_SHA,
    NEXT_PUBLIC_DOMAIN_ROOT_LEVELS: process.env.NEXT_PUBLIC_DOMAIN_ROOT_LEVELS,

    SENTRY_ORG: process.env.SENTRY_ORG,
    SENTRY_PROJECT: process.env.SENTRY_PROJECT,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_SENTRY_SAMPLE_RATE: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE,

    SUPABASE_SERVICE_KEY: process.env.SUPABASE_SERVICE_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,

    NEXT_PUBLIC_MICROSOFT_CLARITY: process.env.NEXT_PUBLIC_MICROSOFT_CLARITY,

    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
        process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,

    RESEND_API_KEY: process.env.RESEND_API_KEY,
};

export const env = pipe(
    envInput,
    envCodec.decode,
    either.mapLeft(errorsToMessage)
);

export const publicEnv = pipe(
    envInput,
    publicEnvCodec.decode,
    either.mapLeft(errorsToMessage)
);

export type Env = t.TypeOf<typeof envCodec>;
export const envFromStringCodec = new t.Type<Env, string, unknown>(
    "EnvFromString",
    envCodec.is,
    (i, c) =>
        pipe(
            t.string.validate(i, c),
            either.chain(
                flow(
                    (s) => s.split("\n"),
                    array.reduce({}, (acc, row) => {
                        const [key, value] = row.split("=");
                        return Object.assign(acc, { [key]: value });
                    }),
                    (i) => envCodec.validate(i, c)
                )
            )
        ),
    flow(
        Object.entries,
        array.map(([key, value]) => `${key}=${value}`),
        (rows) => rows.join("\n")
    )
);
