import * as t from "io-ts";
import { table } from "../utils/pg-ts/pg-ts";
import { commonIdCodec, nullable } from "./common";
import { organizationIdCodec } from "./organization";
import type { RegistrationRow } from "./registration";
import type { AthleteRow } from "./user";
import { pipe } from "fp-ts/function";
import { extractIBAN, ExtractIBANResult } from "ibantools";

// todo https://mojebanka.kb.cz/file/cs/format_qr_kb.pdf
// IBAN / IBAN+BIC
const ibanCodec = t.string;
type Iban = t.TypeOf<typeof ibanCodec>;

// ISO 4217, Exactly 3 characters
const currencyCodec = t.string;
const amountCodec = t.number;
const msgCodec = t.string;
const vsCodec = t.string;

const stripeKindCodec = t.literal("stripe");
const transferKindCodec = t.literal("transfer");

export const paymentMethodTable = table("payment_method", {
    id: commonIdCodec,
    kind: t.union([stripeKindCodec, transferKindCodec]),
    organization_id: organizationIdCodec,
    iban: nullable(ibanCodec),
    // TODO bic, move kind related columns to one json config column?
    // TODO stripe secrets... here or in encrypted table organization secrets?
});

export const transferMethodCodec = t.type(
    {
        kind: transferKindCodec,
        iban: ibanCodec,
    },
    "TransferMethod"
);
export type TransferMethod = t.TypeOf<typeof transferMethodCodec>;

const stripeMethodCodec = t.type(
    {
        kind: stripeKindCodec,
    },
    "StripeMethod"
);

export const paymentMethodCodec = t.union(
    [stripeMethodCodec, transferMethodCodec],
    "PaymentMethod"
);
export type PaymentMethod = t.TypeOf<typeof paymentMethodCodec>;

const transferCodec = t.type(
    {
        iban: ibanCodec,
        // TODO bic
        amount: amountCodec,
        currency: currencyCodec,
        msg: msgCodec,
        reference: vsCodec,
    },
    "Payment"
);

export type Transfer = t.TypeOf<typeof transferCodec>;

export const spd = (t: Transfer) =>
    `SPD*1.0*ACC:${t.iban}*CC:${t.currency}*AM:${t.amount}*MSG:${t.msg}*X-VS:${t.reference}`;

export const paymentTable = table("payments", {
    id: t.string,
    payed: t.boolean,
    registrations: t.array(commonIdCodec),
    // TODO user who created this payment?
    // TODO payment method?
});

export const paymentRegistrationTable = table("payment_registration", {
    payment_id: paymentTable.columns.id,
    registration_id: commonIdCodec,
    price: nullable(amountCodec),
});

// TODO if we add another goods to purchase -> make union...
export interface CartItem
    extends Pick<AthleteRow, "name" | "born">,
        Pick<RegistrationRow, "id" | "race_id" | "category_id"> {
    price: number;
    race: string | null;
}

// TODO CartItemWithCheckedPrice...
interface RegistrationWithCheckedPriceBrand {
    readonly RegistrationWithCheckedPrice: unique symbol;
}
export interface RegistrationWithPrice
    extends Pick<CartItem, "id" | "race_id" | "category_id" | "price"> {
    cartItemTitle: string; // agg for name, born, category, race
}
export type RegistrationWithCheckedPrice = t.Branded<
    RegistrationWithPrice,
    RegistrationWithCheckedPriceBrand
>;

interface IbanParseResult extends ExtractIBANResult {
    localFormat?: string;
}
export const parseIban = (iban: Iban): IbanParseResult => {
    return pipe(iban, extractIBAN, (result) => {
        if (result.countryCode === "CZ") {
            const accountNumber = result.iban.slice(8, 24); // source https://www.cnb.cz/export/sites/cnb/cs/platebni-styk/.galleries/pravni_predpisy/download/vyhl_169_2011.pdf
            const prefix = trimLeadingZeros(accountNumber.slice(0, 6));
            const prefixStr = prefix != "" ? `${prefix}-` : "";
            const base = trimLeadingZeros(accountNumber.slice(6));

            return {
                ...result,
                accountNumber,
                localFormat: `${prefixStr}${base}/${result.bankIdentifier}`,
            };
        } else {
            return result;
        }
    });
};

const trimLeadingZeros = (value: string) => value.replace(/^0+/, "");
