import * as React from "react";
import { useForm as useHookForm, UseFormRegisterReturn } from "react-hook-form";
import { constNull, constVoid, flow, pipe } from "fp-ts/function";
import * as t from "io-ts";
import { either, option, record } from "fp-ts";
import { Control, DefaultValues } from "react-hook-form/dist/types/form";
import { withMessage } from "io-ts-types";
import { Lang, Messages } from "../../utils/i18n/i18n";
import { useLang, useLangCtx } from "../../utils/i18n/lang";
import { errorsToMessage } from "../../utils/fp";
import { UseFormReturn } from "react-hook-form/dist/types";
import type { NullableFields } from "../../utils/types";

export interface InputProps extends UseFormRegisterReturn {
    required: boolean;
    error: boolean;
    control: Control<any>;
    helperText?: React.ReactNode;
}

interface InputDef<C extends t.Mixed> {
    codec: C;
    messages?: Messages<(i: t.InputOf<C>, c: t.Context) => string>;
    helperText?: Messages<string>;
}

const defaultMessages: Messages<() => string> = {
    en: () => "Invalid value",
    cs: () => "Nevalidní hodnota",
};

type InputsDef = Record<string, InputDef<t.Mixed>>;
type InputsType<T extends InputsDef> = {
    [P in keyof T]: t.TypeOf<T[P]["codec"]>;
};

/**
 * reserves space to prevent layout jumps https://mui.com/components/text-fields/#helper-text
 */
export const helperTextPlaceHolder = " ";

function defToProps<D extends InputsDef>(
    defs: D,
    required: boolean,
    lang: Lang,
    form: UseFormReturn<any>
) {
    return pipe(
        defs,
        record.mapWithIndex(
            (key, inputDef): InputProps => ({
                required,
                control: form.control,
                error: form.formState.errors[key] != null,
                helperText:
                    form.formState.errors[key]?.message ||
                    (inputDef.helperText && inputDef.helperText[lang]) ||
                    helperTextPlaceHolder,
                // TODO register should not be called by default, my mui inputs wrappers should decide if they use registrer or control
                ...form.register(key, {
                    required,
                    validate: (value) => {
                        // console.log(required, "key", key, "value", value);
                        const messages = inputDef.messages || defaultMessages;
                        const codec = withMessage(
                            // TODO can be called in in som static context, OR useForm use use memo?
                            inputDef.codec,
                            messages[lang]
                        );

                        return pipe(
                            value,
                            codec.decode,
                            either.fold(
                                (errors): string | true =>
                                    errorsToMessage(errors),
                                (): string | true => true
                            )
                        );
                    },
                }),
            })
        )
    ) as Record<keyof D, InputProps>; // TODO I still do not get how to use mapped types in runtime
}

// TODO slo by zabranit neprazdnemu pruniku keys R a O ?
interface FormDef<R extends InputsDef, O extends InputsDef> {
    required: R;
    optional: O;
}

export type FormValues<F extends FormDef<any, any>> = InputsType<
    F["required"]
> &
    NullableFields<InputsType<F["optional"]>>;

export type FormProps<F extends FormDef<any, any>> = {
    req: Record<keyof F["required"], InputProps>;
    opt: Record<keyof F["optional"], InputProps>;
};

export type FormDefaultValues<F extends FormDef<any, any>> = Partial<
    NullableFields<FormValues<F>>
>;

export const useForm = <R extends InputsDef, O extends InputsDef>(
    formDef: FormDef<R, O>,
    onSubmit: (values: FormValues<FormDef<R, O>>) => void,
    defaultValues?: FormDefaultValues<FormDef<R, O>>,
    autocomplete = false
) => {
    type TFieldValues = FormValues<FormDef<R, O>>;

    const optionalWithNullableCodecs = pipe(
        formDef.optional,
        record.map(
            (inputDef): InputDef<t.Mixed> => ({
                ...inputDef,
                codec: t.union([t.null, t.undefined, inputDef.codec]),
            })
        )
    ) as unknown as Record<keyof O, InputDef<t.Mixed>>; // TODO I still do not get how to use mapped types in runtime

    const formCodec = t.intersection([
        t.type(
            pipe(
                formDef.required,
                record.map((inputDef) => inputDef.codec)
            )
        ),
        t.partial(
            pipe(
                optionalWithNullableCodecs,
                record.map((def) => def.codec)
            )
        ),
    ]) as unknown as t.Type<TFieldValues>;

    const lang = useLang();

    const defaultsEncoder = (defaults?: FormDefaultValues<FormDef<R, O>>) =>
        pipe(
            defaults,
            option.fromNullable,
            option.map(
                // remove values not defined in formDef
                record.filterWithIndex(
                    (name) =>
                        formDef.required[name] != null ||
                        formDef.optional[name] != null
                )
            ),
            option.map(
                record.mapWithIndex((name, value) => {
                    const reqDef = formDef.required[name];
                    const optDef = formDef.optional[name];
                    const def =
                        reqDef != null
                            ? reqDef
                            : optDef != null
                            ? optDef
                            : null;

                    return pipe(
                        option.fromNullable(def),
                        option.chain((def) =>
                            pipe(
                                value,
                                option.fromPredicate(def.codec.is),
                                option.map((value) => ({
                                    value,
                                    def,
                                }))
                            )
                        ),
                        option.map((ctx) => ctx.def.codec.encode(ctx.value)),
                        option.toNullable
                    );
                })
            ),
            option.toNullable
        ) as DefaultValues<TFieldValues>;

    const form = useHookForm<TFieldValues>({
        defaultValues: defaultsEncoder(defaultValues),
    });

    return {
        req: defToProps(formDef.required, true, lang, form),
        opt: defToProps(optionalWithNullableCodecs, false, lang, form),
        encodeAndReset: flow(defaultsEncoder, form.reset),
        // TODO getValue: <T extends keyof TFieldValues>(field: T) =>
        //     pipe(
        //         // @ts-ignore
        //         form.getValues(field),
        //         (val) => {
        //             key: val;
        //         }
        //     ),
        formHooks: form, // TODO hook set/get value is unsafe due to encoders logic!! use direct getValue and implement setValue
        formProps: {
            noValidate: true,
            autoComplete: autocomplete ? undefined : "off",
            onSubmit: form.handleSubmit(
                flow(
                    (values: {}) => ({
                        ...pipe(formDef.optional, record.map(constNull)),
                        ...values,
                    }),
                    // (values) => {
                    //     console.log("onSubmit", values);
                    //     return values;
                    // },
                    formCodec.decode,
                    // (values) => {
                    //     console.log("onSubmit decoded", values);
                    //     return values;
                    // },
                    either.fold(
                        constVoid, // TODO some onInvalid functionality can be added
                        onSubmit
                    )
                )
            ),
        },
    };
};

export const useDisabledForm = <R extends InputsDef, O extends InputsDef>(
    inputs: FormDef<R, O>
) => {
    const form = useHookForm();

    return {
        req: disabledInputProps(inputs.required, true, form),
        opt: disabledInputProps(inputs.optional, false, form),
    };
};

function disabledInputProps<D extends InputsDef>(
    defs: D,
    required: boolean,
    form: UseFormReturn<any>
) {
    return pipe(
        defs,
        record.mapWithIndex((key) => ({
            required,
            name: key,
            disabled: true,
            control: form.control,
            helperText: helperTextPlaceHolder,
        }))
    ) as Record<keyof D, InputProps>; // TODO I still do not get how to use mapped types in runtime
}
