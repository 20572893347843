import * as t from "io-ts";
import { IntFromString } from "io-ts-types";

interface DomainRootLevelsBrand {
    readonly DomainRootLevels: unique symbol;
}

export const domainRootLevelsCodec = t.brand(
    IntFromString,
    (
        n
    ): n is t.Branded<t.TypeOf<typeof IntFromString>, DomainRootLevelsBrand> =>
        n > 0,
    "DomainRootLevels" // the name must match the readonly field in the brand
);

export type DomainRootLevel = t.TypeOf<typeof domainRootLevelsCodec>;

export const subdomainFromHost = (
    host: string,
    domainRootLevels: DomainRootLevel
) => {
    const expectedDomainsCountSubdomainIncluded = domainRootLevels + 1;
    const splitHost = host.split(".", expectedDomainsCountSubdomainIncluded);

    return splitHost.length === expectedDomainsCountSubdomainIncluded
        ? splitHost[0]
        : null;
};

interface SubdomainBrand {
    readonly Subdomain: unique symbol;
}

export const subdomainCodec = t.brand(
    t.string,
    (s): s is t.Branded<string, SubdomainBrand> =>
        s.length >= 3 &&
        !/[\s\.]/g.test(s) &&
        s != "www" &&
        s != "api" &&
        s != "mail",
    "Subdomain"
);

export type Subdomain = t.TypeOf<typeof subdomainCodec>;
