import { failure } from "io-ts/lib/PathReporter";
import { Task } from "fp-ts/Task";
import { Either, Right } from "fp-ts/Either";
import { array, either, number, option, ord, record, string } from "fp-ts";
import { identity, Lazy, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { Predicate } from "fp-ts/Predicate";
import { Ord } from "fp-ts/Ord";
import { Eq } from "fp-ts/Eq";
import { NonEmptyString } from "io-ts-types";
import { Nullable } from "./types";
import * as t from "io-ts";

export const errorsToMessage = (errors: t.Errors): string =>
    failure(errors).join(";\n");

export const runTask = <T>(task: Task<T>): Promise<T> => task();

export const runTaskWithLoadingState =
    <T>(setLoading: (isLoading: boolean) => void) =>
    (t: Task<T>) => {
        setLoading(true);

        return t().then((r) => {
            setLoading(false);
            return r;
        });
    };

export const mapFromArray =
    <A, K, V>(keyMapper: (a: A) => K, valueMapper: (a: A) => V) =>
    (a: Array<A>) =>
        new Map(a.map((i) => [keyMapper(i), valueMapper(i)] as const));

export const rightOrThrow = <L, R>(e: Either<L, R>): R =>
    pipe(
        e,
        either.fold((l) => {
            throw new Error(
                `Right expected, instead got left: ${JSON.stringify(l)}"`
            );
        }, identity)
    );

export const someOrThrow = <V>(o: Option<V>): V =>
    pipe(
        o,
        option.fold(() => {
            throw new Error(`Some expected, instead got none`);
        }, identity)
    );

// https://github.com/samhh/fp-ts-std/blob/master/src/Record.ts
export const pick =
    <A, K extends keyof A>(...ks: Array<K>) =>
    (x: A): Pick<A, K> => {
        const o = {} as Pick<A, K>;

        for (const k of ks) {
            o[k] = x[k];
        }

        return o;
    };

export function updateArrayWhere<T>(
    where: Predicate<T>,
    modifier: (current: T) => T
) {
    return (prevCollection: Array<T>) =>
        pipe(
            prevCollection,
            array.findIndex(where),
            option.chain((updatingIndex) =>
                pipe(
                    prevCollection,
                    array.updateAt(
                        updatingIndex,
                        modifier(prevCollection[updatingIndex])
                    )
                )
            )
        );
}

export function arrayDeleteWhere<T>(where: Predicate<T>) {
    return (prevCollection: Array<T>) =>
        pipe(
            prevCollection,
            array.findIndex(where),
            option.chain((index) => array.deleteAt(index)(prevCollection))
        );
}
/**
 * `Some` is considered to be less than any `None` value.
 */
export const getOptionReverseOrd = <A>(O: Ord<A>): Ord<Option<A>> => ({
    equals: option.getEq(O).equals,
    compare: (x, y) =>
        x === y
            ? 0
            : option.isSome(x)
            ? option.isSome(y)
                ? O.compare(x.value, y.value)
                : -1
            : 1,
});

/**
 * None values are treated as greater than any Some values
 */
export const optNumberReverseOrd = getOptionReverseOrd(number.Ord);

export function recordOrdFromNumericMap<T extends string>(
    numericMap: Record<T, number>
): Ord<string> {
    return ord.fromCompare((first, second) =>
        optNumberReverseOrd.compare(
            record.lookup(first, numericMap),
            record.lookup(second, numericMap)
        )
    );
}

interface Omit {
    <T extends object, K extends [...(keyof T)[]]>(obj: T, ...keys: K): {
        [K2 in Exclude<keyof T, K[number]>]: T[K2];
    };
}

export const omit: Omit = (obj, ...keys) => {
    const ret = {} as {
        [K in keyof typeof obj]: typeof obj[K];
    };
    let key: keyof typeof obj;
    for (key in obj) {
        if (!keys.includes(key)) {
            ret[key] = obj[key];
        }
    }
    return ret;
};

export const optionOrChain =
    <A>(onNone: Lazy<Option<A>>) =>
    (ma: Option<A>) =>
        pipe(ma, option.map(option.some), option.getOrElse(onNone));

export const emptyArray =
    <T>() =>
    (): Array<T> =>
        [];

export const singleItemArrayHead = <T>(a: Array<T>): Option<T> =>
    pipe(
        a,
        option.fromPredicate((names) => names.length === 1),
        option.chain(array.head)
    );

export type RightType<T extends Either<any, any>> = T extends Right<infer R>
    ? R
    : never;

export const NonEmptyStringEq: Eq<NonEmptyString> = string.Eq;

export const sumArrayOfNumbers = array.reduce(
    0,
    (acc, current: number) => acc + current
);

export function takeSomeLeftOrAll<T>(nO: Option<number>) {
    return (a: Array<T>): Array<T> =>
        pipe(
            nO,
            option.map((n) => array.takeLeft(n)(a)),
            option.getOrElse(() => a)
        );
}

export const nullableEq = <T>(eq: Eq<T>): Eq<Nullable<T>> => ({
    equals: (x, y) => {
        if (x === null || y === null) {
            return x === y;
        } else {
            return eq.equals(x, y);
        }
    },
});

export interface TaggedType<Tag extends string, T> {
    _tag: Tag;
    type: T;
}

export const taggedType = <Tag extends string, C extends t.Mixed>(
    tag: Tag,
    codec: C
) => {
    return new t.Type<
        TaggedType<Tag, t.TypeOf<C>>,
        t.OutputOf<C>,
        t.InputOf<C>
    >(
        tag + "_" + codec.name,
        (u): u is TaggedType<Tag, t.TypeOf<C>> =>
            // @ts-ignore
            u._tag === tag && codec.is(u.type),
        (u, c) =>
            pipe(
                codec.validate(u, c),
                either.chain((type) =>
                    t.success({
                        _tag: tag,
                        type,
                    })
                )
            ),
        (a) => a.type
    );
};
