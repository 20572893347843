import { PartialC, Props, TypeC, TypeOf } from "io-ts";
import { array, record, string } from "fp-ts";
import { pipe } from "fp-ts/function";
import { SupabaseClient } from "@supabase/supabase-js";

type Cols = Props;
type ColName = string;

interface Table<Name extends ColName, C extends Cols> {
    name: Name;
    columns: C;
}
export const table = <N extends ColName, C extends Cols>(
    name: N,
    columns: C
): Table<N, C> => ({
    name,
    columns,
});

type Column<T extends Table<any, any>> = keyof T["columns"];

// existing column assertion
export const column = <T extends Table<any, any>>(t: T, column: Column<T>) =>
    column;

export type TableRowT<T extends Table<any, any>> = TypeOf<TypeC<T["columns"]>>;
export const columns = (
    cols: Record<string, unknown>,
    ...others: Array<string>
) =>
    pipe(cols, record.keys, array.union(string.Eq)(others), (keys) =>
        keys.join(", ")
    );

export const join = <T extends Table<any, any>>(
    table: T,
    cols: Partial<T["columns"]>,
    alias?: string,
    inner = false,
    ...others: Array<string>
) =>
    `${alias ? `${alias}:` : ""}${table.name}${inner ? "!inner" : ""}(${columns(
        cols,
        ...others
    )})`;

export const insert =
    <T extends Table<any, any>>(
        table: T,
        values:
            | TypeOf<PartialC<T["columns"]>>
            | Array<TypeOf<PartialC<T["columns"]>>>
    ) =>
    (supabase: SupabaseClient) =>
        supabase.from(table.name).insert(values);

// TODO update

// import * as t from "io-ts";
// import { UUID } from "io-ts-types";
//
// export interface ColumnType<C extends t.Mixed> {
//     codec: C;
//     sqlType: string;
// }
//
// const columnType = <C extends t.Mixed>(
//     codec: C,
//     sqlType: string
// ): ColumnType<C> => ({
//     codec,
//     sqlType,
// });
//
// // just a sample
// const int = columnType(t.number, "INTEGER");
//
// type Columns = { [columnName: string]: ColumnType<t.Mixed> };
// type TypeOfColumn<C extends ColumnType<any>> = t.TypeOf<C["codec"]>;
//
// type TypeOfColumns<P extends Columns> = {
//     [K in keyof P]: TypeOfColumn<P[K]>;
// };
//
// // type ColumnNames<C extends Columns> = keyof C;
//
// interface DefaultProvider<T> {
//     sql: string;
// }
//
// const genRandomUuid: DefaultProvider<number> = {
//     // TODO UUUID
//     sql: "gen_random_uuid()",
// };
//
// interface Table<C extends Columns> {
//     columns: C;
//     defaults?: Partial<{ [K in keyof C]: DefaultProvider<TypeOfColumn<C[K]>> }>;
// }

// const table = <C extends Columns>(
//     columns: C,
//     defaults: Partial<{ [K in keyof C]: DefaultProvider<TypeOfColumn<C[K]>> }>
// ) => ({});
//
// const tableX = table({ born: int, weight: int }, { born: genRandomUuid });

// type T = TypeOfColumns<typeof columns>;
//
// type Schema = { [tableName: string]: Columns };
//
// type TypeOfSchema<P extends Schema> = {
//     [K in keyof P]: TypeOfColumns<P[K]>;
// };
//
// const schema = {
//     children: columns,
// };
//
// type S = TypeOfSchema<typeof schema>;
//
// const insert = <S extends Schema, T extends keyof S>(
//     schema: S,
//     table: T,
//     row: TypeOfColumns<S[T]>
// ) => {};
//
// insert(schema, "children", { born: 4 });
