import * as React from "react";
import { InputProps } from "./form";
import { TextField, InputAdornment } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Controller } from "react-hook-form";
import { messages } from "../../utils/i18n/i18n";
import { useTranslation } from "../../utils/i18n/lang";

interface Props {}

interface Props
    extends InputProps,
        Pick<TextFieldProps, "fullWidth" | "autoFocus"> {
    label: React.ReactNode;
    autoComplete: "current-password" | "new-password" | "off";
}

const PasswordInputInner = (
    p: Props,
    _ref: React.ForwardedRef<HTMLDivElement>
) => {
    const [isVisible, setIsVisible] = React.useState(false);

    const handleClickShowPassword = () => {
        setIsVisible((current) => !current);
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const m = useTranslation(msg);

    return (
        <Controller
            name={p.name}
            control={p.control}
            render={(formProps) => (
                <TextField
                    {...formProps.field}
                    required={p.required}
                    size={"small"}
                    value={formProps.field.value || ""}
                    label={p.label}
                    error={p.error}
                    helperText={p.helperText}
                    type={isVisible ? "text" : "password"}
                    fullWidth={p.fullWidth}
                    InputProps={{
                        autoComplete: p.autoComplete,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={
                                        isVisible
                                            ? m.ariaLabelHide
                                            : m.ariaLabelShow
                                    }
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

const msg = messages(
    {
        ariaLabelHide: "hide password",
        ariaLabelShow: "show password",
    },
    {
        cs: {
            ariaLabelHide: "skrýt heslo",
            ariaLabelShow: "zobrazit heslo",
        },
    }
);

export const PasswordInput = React.forwardRef(PasswordInputInner);
