import { createClient } from "@supabase/supabase-js";
import { rightOrThrow } from "../fp";
import { pipe } from "fp-ts/function";
import { publicEnv } from "../env/env";

export const supabase = pipe(publicEnv, rightOrThrow, (validEnv) =>
    createClient(
        validEnv.NEXT_PUBLIC_SUPABASE_URL,
        validEnv.NEXT_PUBLIC_SUPABASE_ANON_KEY
    )
);
