// common for frontend and backend
// validated env cannot be used because we are in js here and compiled ent.js may not exist yet. Validation still happens before build
export const sentryCommonConfig = {
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    tracesSampleRate: Number.parseFloat(
        process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE
    ),
};
