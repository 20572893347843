import * as React from "react";
import { Lang, Messages, Translation } from "./i18n";
import { Distance } from "../../domain/common";
import { assertUnreachable } from "../assertUnreachable";
import { distanceFormat } from "../../domain/distance";

interface LangCtx {
    lang: Lang;
    distanceFormat: (distance: Distance) => string;
}
type Context = [LangCtx, (ctx: LangCtx) => void];

const LangContext = React.createContext<Context | undefined>(undefined);

interface Props {
    lang: Lang;
    children: React.ReactNode;
}

export const LangProvider = (p: Props) => {
    const state = React.useState<LangCtx>(() => {
        const locale = localeForLang(p.lang);

        return {
            lang: p.lang,
            distanceFormat: distanceFormat(locale),
        };
    });

    return (
        <LangContext.Provider value={state}>{p.children}</LangContext.Provider>
    );
};

export const useLangCtx = () => {
    const context = React.useContext(LangContext);
    if (context === undefined) {
        throw new Error("useLang must be used inside LangContext");
    }

    return context;
};

export const useLang = () => {
    const context = useLangCtx();
    return context[0].lang;
};

export function useTranslation<M extends Messages<any>>(
    messages: M
): Translation<M> {
    const lang = useLang();
    return messages[lang];
}

export const localeForLang = (lang: Lang): string => {
    switch (lang) {
        case "cs":
            return "cs-CZ";

        case "en":
            return "en-US";
    }

    assertUnreachable(lang);
};
