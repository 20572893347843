import * as React from "react";

interface Props {
  value?: string;
}

export const PasswordManagerUsernameInput = (p: Props) => {
  return (
    <input
      style={{ display: "none" }}
      name="email"
      autoComplete="username email"
      value={p.value || ""}
      readOnly
    />
  );
};
