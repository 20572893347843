import { table } from "../utils/pg-ts/pg-ts";
import { commonIdCodec } from "./common";
import { organizationsTable } from "./organization";
import { NonEmptyString } from "io-ts-types";

export const enumsTable = table("enums", {
    id: commonIdCodec,
    organization_id: organizationsTable.columns.id,
    title: NonEmptyString, // TODO length?
});

export const enumItemsTable = table("enum_items", {
    id: commonIdCodec,
    enum_id: enumsTable.columns.id,
    title: NonEmptyString, // TODO length?
});
