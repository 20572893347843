import { Subdomain, subdomainCodec } from "../utils/subdomain/subdomain";
import * as t from "io-ts";
import { bornCodec, commonIdCodec, nullable } from "./common";
import { table } from "../utils/pg-ts/pg-ts";
import { NonEmptyString } from "io-ts-types";
import { fullNameCodec, sexCodec } from "./user";
import { pick } from "../utils/fp";
import { pipe } from "fp-ts/function";

export type ConfirmedUniqueSubdomain = {
    type: "confirmed";
    subdomain: Subdomain;
};
export const organizationNameCodec = t.string; // TODO some length restriction
export const organizationIdCodec = commonIdCodec;
export type OrganizationId = t.TypeOf<typeof organizationIdCodec>;

export interface Organization {
    name: string;
    subdomain: ConfirmedUniqueSubdomain;
    // owner: User;
}

export interface Event {
    start: Date;
    end: Date;
    location: string; // TODO, merge with related event  domain
}

// Sync changes in pg function organizations_bucket_ids_where_editor
export const organizationPublicBucketId = (id: OrganizationId) => `org-${id}`;

export const organizationsTable = table("organizations", {
    id: commonIdCodec,
    name: NonEmptyString, // TODO rename to title?
});

export const organizationAthleteTable = table("organization_athletes", {
    id: commonIdCodec,
    name: fullNameCodec,
    born: bornCodec,
    sex: sexCodec,
    club: nullable(NonEmptyString), // TODO club is repeated everywhere right now... athletes, results, organization_athletes
});

export const organizationsWithSubdomainView = table(
    "organizations_with_subdomain",
    {
        ...organizationsTable.columns,
        subdomain: subdomainCodec,
    }
);

export const organizationAthletesListView = table(
    "organization_athletes_list",
    {
        subdomain: subdomainCodec,
        results_count: t.Int,
        ...pipe(
            organizationAthleteTable.columns,
            pick("id", "name", "born", "sex", "club")
        ),
    }
);
