import {
    commonIdCodec,
    distanceCodec,
    localDateStringCodec,
    mdxInputCodec,
    nullable,
    pgIntegerCodec,
    pgSmallintCodec,
    PositiveInt,
    positiveIntCodec,
    rectSizeCodec,
    timeStampRangeCodec,
    urlCodec,
    validIsoDateStringCodec,
    validIsoDateStringFromDateCodec,
} from "./common";
import { User } from "./user";
import * as t from "io-ts";
import { nonEmptyArray, NonEmptyString } from "io-ts-types";
import { messages } from "../utils/i18n/i18n";
import { table, TableRowT } from "../utils/pg-ts/pg-ts";
import { organizationIdCodec } from "./organization";
import { geoJsonCodec } from "../utils/geo/geoJson";
import { pipe } from "fp-ts/lib/function";
import { sequenceS } from "fp-ts/Apply";
import { date, option } from "fp-ts";
import { between } from "fp-ts/Ord";
import { categoriesTable } from "./category";
import { gunshotsTable } from "./timing";
import { clubScoringCodec } from "./race";
import { flow } from "fp-ts/function";
import { rightOrThrow } from "../utils/fp";
import { assertUnreachable } from "../utils/assertUnreachable";
import { enumsTable } from "./enum";
import { paymentMethodTable } from "./payment";

export const sportLabels = messages(
    {
        run: "run",
        roadCycling: "road cycling",
        mtb: "MTB",
        xcSki: "cross country ski",
        other: "other",
    },
    {
        cs: {
            run: "běh",
            roadCycling: "silniční cyklistika",
            mtb: "MTB",
            xcSki: "běžecké lyžování",
            other: "ostatní",
        },
    }
);

const sportCodec = t.keyof(sportLabels.en);
export type Sport = t.TypeOf<typeof sportCodec>;

export const imagesTable = table("images", {
    id: commonIdCodec,
    organization_id: organizationIdCodec,
    url: t.string,
    metadata: t.type(
        {
            size: rectSizeCodec,
            storageKey: t.string,
        },
        "metadata"
    ),
});

export const events = table("events", {
    id: commonIdCodec,
    title: NonEmptyString, // TODO some length restriction
    subtitle: nullable(NonEmptyString),
    description: nullable(mdxInputCodec),
    date: nullable(localDateStringCodec),
    date_canceled: nullable(localDateStringCodec),
    organization_id: organizationIdCodec,
    banner_image_id: nullable(imagesTable.columns.id),
    location: nullable(NonEmptyString),
    web: nullable(urlCodec),
    registration: nullable(timeStampRangeCodec),
    results_published: t.boolean,
    updated_at: validIsoDateStringCodec,
    payment_method_id: nullable(paymentMethodTable.columns.id),
});
export type EventRow = TableRowT<typeof events>;

export const organizationEventsView = table("organization_events_list", {
    ...events.columns,
});

type IsRegistrationOpenInput = Pick<EventRow, "date" | "registration">; // TODO event has filled races?, reason why registration is closed can be visible in admin
export const isRegistrationOpen = (now: Date, input: IsRegistrationOpenInput) =>
    pipe(
        {
            date: option.fromNullable(input.date),
            registration: option.fromNullable(input.registration),
        },
        sequenceS(option.Apply),
        option.filter((ctx) => {
            const start = validIsoDateStringFromDateCodec.encode(
                ctx.registration[0]
            );

            const end = validIsoDateStringFromDateCodec.encode(
                ctx.registration[1]
            );

            return pipe(now, between(date.Ord)(start, end));
        })
    );

export type EventId = t.TypeOf<typeof events.columns.id>;
export const eventUpdateResultCodec = t.type(
    events.columns,
    "EventUpdateResult"
);
export type EventUpdateResult = t.TypeOf<typeof eventUpdateResultCodec>;

export const eventMessages = messages(
    {
        title: "Title",
        subtitle: "Subtitle",
        description: "Description",
        date: "Date",
        sports: "Sports",
        location: "Location",
        web: "Event's web",
    },
    {
        cs: {
            s: "", // TODO should fail on ts
            title: "Název",
            date: "Datum",
            subtitle: "Podtitul",
            description: "Popis",
            sports: "Sport",
            location: "Místo",
            web: "Web události",
        },
    }
);

// // TODO interfaces
// export type Event = {
//     title: t.TypeOf<typeof eventTitleCodec>; // 2. mile Josefa Odlozila
//     owner: Owner;
//     editors: Array<Editor>;
// };

export type Owner = {
    user: User;
};

export type Editor = {
    user: User;
};

export type EventVolume = {
    start: Date;
};

export type CreateEvent = () => void;

export type Age = PositiveInt;

const segmentIdCodec = commonIdCodec;
export type SegmentId = t.TypeOf<typeof segmentIdCodec>;

export const segmentTable = table("segments", {
    id: segmentIdCodec,
    title: NonEmptyString, // TODO some limitation
    distance: distanceCodec,
    organization_id: organizationIdCodec,
    geojson: nullable(geoJsonCodec),
});
export type SegmentTableRow = TableRowT<typeof segmentTable>;
export const segmentMessages = messages(
    {
        title: "Title",
        distance: "Distance",
    },
    {
        cs: {
            title: "Název",
            distance: "Vzdálenost",
        },
    }
);

// TODO duration neni moc přesné, spíš fluidDistance/fixed distance
export const raceKindLabels = messages(
    {
        distance: "fixed distance (e.g 10 miles)",
        duration: "fixed time (e.g. 12 Hours race)",
    },
    {
        cs: {
            distance: "na určenou vzdáleností (např. 10km)",
            duration: "na určený čas (např. 12-hodinovka)",
        },
    }
);
export const raceKindCodec = t.keyof(raceKindLabels.en);
export type RaceKind = t.TypeOf<typeof raceKindCodec>;
// from race kind can be later initialized result ordering (by time, by finished distance (finished segments count))

export const raceIdCodec = commonIdCodec;
export type RaceId = t.TypeOf<typeof raceIdCodec>;

// unit is seconds/km TODO allow input in different values, cycling -> speed? Running min/km
export const paceCodec = t.intersection(
    [positiveIntCodec, pgIntegerCodec],
    "Pace"
);
export type Pace = t.TypeOf<typeof paceCodec>;

export const paceMaxDefault = flow(
    (sport: Sport) => {
        switch (sport) {
            case "roadCycling":
                return 60; // 60km/h

            case "mtb":
                return 70;

            case "run":
                return 150; // 2.5min/km

            case "xcSki":
            case "other":
                return 120; // 2min/km
        }
        assertUnreachable(sport);
    },
    paceCodec.decode,
    rightOrThrow
);

export const raceNumberFieldCodec = t.type({
    kind: t.literal("raceNumber"),
    enum_id: enumsTable.columns.id,
    required: t.boolean,
});

export type RaceNumberField = t.TypeOf<typeof raceNumberFieldCodec>;
export const commonStringFieldCodec = t.type({
    kind: t.literal("commonString"),
    required: t.boolean,
    label: t.string,
});
export type CommonStringField = t.TypeOf<typeof commonStringFieldCodec>;

export const registrationFieldCodec = t.union([
    raceNumberFieldCodec,
    commonStringFieldCodec,
]);
export type RegistrationField = t.TypeOf<typeof registrationFieldCodec>;

export const raceTable = table("races", {
    id: raceIdCodec,
    title: NonEmptyString, // TODO some length restriction
    sport: sportCodec,
    start_time: validIsoDateStringCodec,
    kind: raceKindCodec,
    event_id: events.columns.id,
    gunshot_id: nullable(gunshotsTable.columns.id),
    club_scoring: nullable(clubScoringCodec),
    rank: nullable(pgIntegerCodec),
    fastest_expected_pace: nullable(paceCodec),
    registration_fields: nullable(t.array(registrationFieldCodec)),
});
export const raceSegmentTable = table("race_segment", {
    race_id: raceIdCodec,
    segment_id: segmentIdCodec,
    ordering: pgSmallintCodec,
});
export const raceCategoryTable = table("race_category", {
    race_id: raceIdCodec,
    category_id: categoriesTable.columns.id,
    entry_fee: nullable(t.number),
    entry_fee_currency: nullable(t.string),
});

export const raceMessages = messages(
    {
        title: "Title",
        sport: "Sport",
        start_time: "Start time",
        categories: "Categories",
        kind: "Race kind",
        course: "Course",
        circuit: "Circuit",
        fee: "fee",
    },
    {
        cs: {
            title: "Název",
            sport: "Sport",
            start_time: "Čas startu",
            categories: "Kategorie",
            kind: "Druh závodu",
            course: "Trať",
            circuit: "Okruh",
            fee: "startovné",
        },
    }
);

export const courseCodec = t.type({
    kind: raceKindCodec,
    segments: nonEmptyArray(segmentTable.columns.id),
});

export type Course = t.TypeOf<typeof courseCodec>;

export const fileTable = table("files", {
    id: commonIdCodec,
    organization_id: organizationIdCodec,
    event_id: nullable(events.columns.id),
    title: NonEmptyString,
    url: NonEmptyString, // TODO url?
    metadata: t.type(
        {
            storageKey: t.string,
        },
        "metadata"
    ),
});
