import * as React from "react";
import { useContext } from "react";
import { option } from "fp-ts";
import { Session, SupabaseClient, User } from "@supabase/supabase-js";
import { Option } from "fp-ts/Option";
import * as t from "io-ts";

export const useUser = (): Option<User> => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error(`useUser must be used within a UserContextProvider.`);
    }
    return option.fromNullable(context.user);
};

export const supabaseUserCodec = t.type(
    {
        id: t.string,
        email: t.string,
        // there are more fields, see User
    },
    "supabaseUser"
);

export interface AuthSession {
    user: User | null;
    session: Session | null;
}

const UserContext = React.createContext<AuthSession>({
    user: null,
    session: null,
});

export interface Props {
    supabaseClient: SupabaseClient;
    [propName: string]: any;
}

export const UserContextProvider = (props: Props) => {
    const { supabaseClient } = props;
    const [session, setSession] = React.useState<Session | null>(null);
    const [user, setUser] = React.useState<User | null>(null);

    React.useEffect(() => {
        const session = supabaseClient.auth.session();
        setSession(session);
        setUser(session?.user ?? null);

        const { data: authListener } = supabaseClient.auth.onAuthStateChange(
            async (event, session) => {
                console.log("onAuthStateChange", event, session);
                setSession(session);
                setUser(session?.user ?? null);
            }
        );

        return () => {
            authListener?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = {
        session,
        user,
    };

    return <UserContext.Provider value={value} {...props} />;
};
