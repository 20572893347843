import { flow, identity, pipe } from "fp-ts/function";
import { either } from "fp-ts";
import { Either } from "fp-ts/Either";
import * as t from "io-ts";
import turfBbox from "@turf/bbox";
import turfLength from "@turf/length";
import { BBox2d, FeatureCollection } from "@turf/helpers/dist/js/lib/geojson";
import { Distance, distanceCodec } from "../../domain/common";
import { errorsToMessage } from "../fp";
// @ts-ignore
import { gpx } from "@tmcw/togeojson";

// const geometryCodec = t.type({
//     type: t.union([
//         t.literal("Point"),
//         t.literal("MultiPoint"),
//         t.literal("LineString"),
//         t.literal("MultiLineString"),
//         t.literal("Polygon"),
//         t.literal("MultiPolygon"),
//         t.literal("GeometryCollection"),
//     ]),
// });
//
// const featureCodec = t.type({ type: t.literal("Feature") });
// const featureCollectionCodec = t.type({ type: t.literal("FeatureCollection") });
//
// const geoJsonCodec = t.union(
//     [geometryCodec, featureCodec, featureCollectionCodec],
//     "GeoJson"
// );

export const geoJsonCodec = t.type({}, "GeoJson");
// todo schema not complete, just used parts
export type GeoJson = t.TypeOf<typeof geoJsonCodec>;

const geoJsonParse = flow(
    geoJsonCodec.decode,
    either.mapLeft((e) => `json parse failed: ${e}`)
);

export const fromGpx = (gpxStr: string): Either<string, GeoJson> =>
    pipe(
        either.tryCatch(() => {
            return new DOMParser().parseFromString(gpxStr, "application/xml");
        }, String),
        either.chain((doc) =>
            either.tryCatch(
                () => gpx(doc),
                (e) => `gpx parse failed: ${e}`
            )
        ),
        either.chain(geoJsonParse)
    );

export const boundingBox = (geoJson: GeoJson): Either<unknown, BBox2d> =>
    pipe(
        either.tryCatch(() => turfBbox(geoJson), identity),
        either.map(([a, b, c, d]) => [a, b, c, d])
    );

export const distance = (geoJson: GeoJson): Either<unknown, Distance> =>
    pipe(
        either.tryCatch(
            () => turfLength(geoJson as FeatureCollection, { units: "meters" }),
            String
        ),
        either.chain(
            flow(
                Math.round,
                distanceCodec.decode,
                either.mapLeft(errorsToMessage)
            )
        )
    );
