import * as t from "io-ts";

export const langCodec = t.keyof({
    en: null,
    cs: null,
});

export type Lang = t.TypeOf<typeof langCodec>;
export const langDefault: Lang = "en";
export type LangDefault = typeof langDefault;
type LangNonDefault = Exclude<Lang, LangDefault>;
export type Messages<T> = Record<Lang, T>;

export const messages = <
    M extends Record<string, any>,
    O extends Record<LangNonDefault, M> // TODO catch props not listed in defaults
>(
    defaults: M,
    nonDefaults: O
): Messages<M> => ({
    ...nonDefaults,
    en: defaults,
});

export type Translation<M extends Messages<any>> = M extends Messages<infer R>
    ? R
    : any;
